@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@layer tailwind-variants {
  @tailwind variants;
}

/* You can add global styles to this file, and also import other style files */
